// import { PageNotFound } from "components/common";
import React from "react";
import { ReceiveAssignment } from "./components";
import { useParams } from "react-router-dom";

const Assignment: React.FC = () => {
  const { id: caseId } = useParams<{ id: string }>();

  return <ReceiveAssignment caseId={caseId} />;
};

export default Assignment;
