import "fontsource-overpass/400.css";
import "fontsource-overpass/700.css";
import "fontsource-overpass/900.css";
import React, { useLayoutEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Header, Footer, PageNotFound } from "components/common";
import { ThemeProvider } from "@material-ui/core";
import Offer from "pages/offer";
import UploadDocument from "pages/upload-document";
import ApolloProvider from "context/apollo";
import MainTheme from "themes/MainTheme";
import { URL_ACCEPT_OFFER, URL_ASSIGNMENT_RECEIVE, URL_UPLOAD_DOCUMENT } from "./constants";
import "./index.scss";
import Assignment from "pages/assignment";

const App: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-fluid">
      <ApolloProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ThemeProvider theme={MainTheme}>
            <Header />
            <Switch>
              <Route path={URL_ASSIGNMENT_RECEIVE} component={Assignment} />
              <Route path={[URL_ACCEPT_OFFER]} component={Offer} />
              <Route exact path={URL_UPLOAD_DOCUMENT} component={UploadDocument} />
              <Route component={PageNotFound} />
            </Switch>
            <Footer />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </ApolloProvider>
    </div>
  );
};

export default App;
